import { getSEO, PageSEO } from '@seo';
import React from 'react';

interface Props {
  location: {
    pathname: string;
  };
}

const IomKabulAfghanistan = ({ location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-default.jpg"
        pathname={pathname}
        title={seo.title}
      />
      Project IomKabulAfghanistan
    </>
  );
};

export default IomKabulAfghanistan;
